import React, { Fragment } from "react";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { IconElement } from "../../elements/icon.element";
import { classNames } from "../../../helpers/class-names.helper";

export function BlockFooterMission({ data = {}, assets, color }) {
  const {
    logo,
    mission,
    copyright,
    social,
    navigation,
    isDark = false,
    isPrimary = false,
  } = data;

  return (
    <footer>
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-4 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            {logo && (
              <ImageAssetElement
                assetKey={logo}
                assets={assets}
                className="h-10"
                data-tracking-action-label="rescue.trap"
                data-tracking-action-value="echonovum logo landscape"
                data-tracking-action-position="footer"
              />
            )}

            {mission && (
              <p
                className={classNames([
                  "text-lg leading-normal",
                  isPrimary && color.text[300],
                  isDark && "text-gray-200",
                  !isPrimary && !isDark && "text-gray-700",
                ])}
              >
                {mission}
              </p>
            )}

            {social && (
              <div className="flex space-x-6">
                {social.map(({ title, icon, link }) => {
                  return (
                    <a
                      href={link}
                      className={classNames([
                        "text-base",
                        isPrimary &&
                          `${color.text[300]} hover:${color.text[200]}`,
                        isDark && "text-gray-300 hover:text-gray-100",
                        !isPrimary &&
                          !isDark &&
                          "text-gray-400 hover:text-gray-700",
                      ])}
                      key={title}
                      data-tracking-action-label="social.outbound"
                      data-tracking-action-value={title}
                      data-tracking-action-position="footer"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="sr-only">{title}</span>
                      <IconElement icon={icon} />
                    </a>
                  );
                })}
              </div>
            )}
          </div>

          <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 xl:mt-0 xl:col-span-3">
            {Array.isArray(navigation) && (
              <Fragment>
                {navigation.map(({ title, links }) => {
                  return (
                    <div className="mt-4 md:mt-0" key={title}>
                      <div
                        className={classNames([
                          "text-sm leading-5 font-semibold tracking-wider uppercase",
                          isPrimary && color.text[100],
                          isDark && "text-gray-100",
                          !isPrimary && !isDark && "text-gray-900",
                        ])}
                      >
                        {title}
                      </div>
                      {Array.isArray(links) && (
                        <ul className="mt-4 space-y-4">
                          {links.map(({ title, link }) => {
                            return (
                              <li key={title}>
                                <a
                                  href={link}
                                  className={classNames([
                                    "text-base leading-6",
                                    isPrimary &&
                                      `${color.text[300]} hover:${color.text[200]}`,
                                    isDark &&
                                      "text-gray-300 hover:text-gray-100",
                                    !isPrimary &&
                                      !isDark &&
                                      "text-gray-700 hover:text-gray-800",
                                  ])}
                                  data-tracking-action-label="outbound"
                                  data-tracking-action-value={title}
                                  data-tracking-action-position="footer"
                                >
                                  {title}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  );
                })}
              </Fragment>
            )}
          </div>
        </div>
        <div className="mt-12 border-t-0 border-gray-200 md:flex md:flex-wrap justify-between items-center">
          <div
            className={classNames([
              "text-base leading-6 text-gray-400 xl:text-center",
              isPrimary && color.text[300],
              isDark && "text-gray-300",
              !isPrimary && !isDark && "text-gray-700",
            ])}
          >
            {copyright}
          </div>
          <div
            className={classNames([
              "text-sm leading-6 text-gray-400 xl:text-center",
              isPrimary && color.text[300],
              isDark && "text-gray-300",
              !isPrimary && !isDark && "text-gray-700",
            ])}
          >
            <a href="/" className="hover:underline hover:cursor-pointer">
              Deutsch
            </a>{" "}
            <span className="opacity-50">|</span>{" "}
            <a href="/en" className="hover:underline hover:cursor-pointer">
              English
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export { BlockFooterMission as default };

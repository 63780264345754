import React, { Fragment, useState } from "react";
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { IconElement } from "../icon.element";

export function NavbarTopMenuItemMobileElement({
  title,
  icon,
  link,
  links,
  color,
}) {
  const hasSublinks = Array.isArray(links) && links.length;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      {!hasSublinks && (
        <a
          href={link}
          className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
        >
          {icon && (
            <div
              className={`flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md ${color.bg[500]} text-white`}
            >
              <IconElement icon={icon} />
            </div>
          )}

          <div className="text-base leading-6 font-medium text-gray-900">
            {title}
          </div>
        </a>
      )}

      {hasSublinks && (
        <Fragment>
          <button
            href={link}
            onClick={() => setIsOpen(!isOpen)}
            className="-m-3 p-3 flex items-center justify-between space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <div className="flex items-center">
              {icon && (
                <div
                  className={`mr-4 flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md ${color.bg[500]} text-white`}
                >
                  <IconElement icon={icon} />
                </div>
              )}
              <div className="text-base leading-6 font-medium text-gray-900">
                {title}
              </div>
            </div>

            <svg
              className={classNames({
                "h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150": true,
                "text-gray-400": !isOpen,
                "text-gray-600": isOpen,
              })}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <Transition
            className="-mt-1 transform w-full z-10"
            show={isOpen}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            {links && (
              <Fragment>
                {links.map(({ title, link, icon, description }) => {
                  const unique = title + link + icon;
                  return (
                    <a
                      href={link}
                      key={unique}
                      className="mb-3 -m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <div
                        className={`flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md ${color.bg[100]} ${color.text[500]} sm:h-12 sm:w-12`}
                      >
                        <IconElement icon={icon} />
                      </div>
                      <div className="space-y-1">
                        <p className="text-base leading-6 font-medium text-gray-900">
                          {title}
                        </p>
                      </div>
                    </a>
                  );
                })}
              </Fragment>
            )}
          </Transition>
        </Fragment>
      )}
    </Fragment>
  );
}

export function getImageAsset(key, assets) {
  if (typeof key !== "string" && assets) {
    return null;
  }

  const asset = assets?.[key] || null;

  // asset not in assets map
  if (!asset) {
    return null;
  }

  // exit if asset is not an image
  if (!(asset?.src?.sourceInstanceName === "images")) {
    return null;
  }

  // File not found = src: null
  return asset;
}

export function getFluidImage(asset) {
  if (!asset) {
    return null;
  }

  return asset?.src?.childImageSharp?.fluid;
}

export function getFixedImage(asset) {
  if (!asset) {
    return null;
  }

  return asset?.src?.childImageSharp?.fixed;
}

export function getPublicUrl(asset) {
  if (!asset) {
    return null;
  }

  return asset?.src?.publicURL || null;
}

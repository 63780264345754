import React, { Fragment, useState } from "react";
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { IconElement } from "../icon.element";

export function NavbarTopMenuItemElement({
  title,
  link,
  links,
  color,
  isPrimary = false,
  isDark = false,
}) {
  const hasSublinks = Array.isArray(links) && links.length;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      {!hasSublinks && (
        <a
          href={link}
          className={classNames({
            "whitespace-nowrap text-base leading-6 font-bold focus:outline-none transition ease-in-out duration-150": true,
            [`text-gray-900 hover:${color.text[500]} focus:${color.text[500]}`]:
              !isPrimary && !isDark,
            [`${color.text[100]} hover:${color.text[50]} focus:${color.text[50]}`]: isPrimary,
            "text-gray-100 hover:text-gray-50 focus:text-gray-50": isDark,
          })}
        >
          {title}
        </a>
      )}

      {hasSublinks && (
        <div className="relative z-10">
          <button
            type="button"
            onFocus={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
            className={classNames({
              "group text-gray-900 inline-flex items-center space-x-2 text-base leading-6 font-bold focus:outline-none transition ease-in-out duration-150": true,
              [`text-gray-900 hover:${color.text[500]} focus:${color.text[500]}`]:
                !isPrimary && !isDark,
              [`${color.text[100]} hover:${color.text[50]} focus:${color.text[50]}`]: isPrimary,
              "text-gray-100 hover:text-gray-50 focus:text-gray-50": isDark,
            })}
          >
            <span className="whitespace-nowrap">{title}</span>

            <svg
              className={classNames({
                [`text-gray-900 group-hover:${color.text[500]} group-focus:${color.text[500]}`]:
                  !isPrimary && !isDark,
                [`${color.text[400]} group-hover:${color.text[100]} group-focus:${color.text[100]}`]: isPrimary,
                "text-gray-400 group-hover:text-gray-100 group-focus:text-gray-100": isDark,
                "h-5 w-5 transition ease-in-out duration-150": true,
              })}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <Transition
            className="absolute -ml-4 mt-3 transform w-screen max-w-md md:max-w-3xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 z-10"
            show={isOpen}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <div className="rounded-lg shadow-lg">
              <div className="rounded-lg shadow-xs overflow-hidden">
                <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                  {links && (
                    <Fragment>
                      {links.map(({ title, link, icon, description }) => {
                        const unique = title + link + icon;
                        return (
                          <a
                            href={link}
                            key={unique}
                            className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          >
                            <div
                              className={`flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-100 x${color.bg[500]} text-gray-800 sm:h-12 sm:w-12`}
                            >
                              <IconElement
                                className="text-xl h-6 w-6"
                                icon={icon}
                              />
                            </div>
                            <div className="space-y-1">
                              <p className="text-base leading-6 font-bold text-gray-900">
                                {title}
                              </p>
                              <p className="text-sm leading-5 text-gray-700">
                                {description}
                              </p>
                            </div>
                          </a>
                        );
                      })}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      )}
    </Fragment>
  );
}

import React, { Fragment } from "react";
import {
  getFixedImage,
  getFluidImage,
  getImageAsset,
  getPublicUrl,
} from "../../helpers/assets.helper";
import Img from "gatsby-image";

export function ImageAssetElement({
  assetKey,
  assets,
  className = {},
  isFluid = false,
  isFixed = false,
  objectFit = "contain",
  objectPosition = "0 0",
}) {
  const asset = getImageAsset(assetKey, assets);

  const fluid = isFluid ? getFluidImage(asset) : null;

  const fixed = isFixed && !isFluid ? getFixedImage(asset) : null;

  const publicUrl = !(fluid || fixed) ? getPublicUrl(asset) : null;

  const _objectPosition =
    objectFit === "cover" ? "center center" : objectPosition;

  return (
    <Fragment>
      {fluid && (
        <Img
          fluid={fluid}
          alt={asset.alt}
          style={{ maxHeight: "100%" }}
          imgStyle={{
            objectFit,
            objectPosition: _objectPosition,
          }}
          className={className + " w-full"}
        />
      )}
      {fixed && <Img fluid={fixed} alt={asset.alt} className={className} />}
      {publicUrl && (
        <img src={publicUrl} alt={asset.alt} className={className} />
      )}
    </Fragment>
  );
}
